"use client";

import { Box, Spinner, useToast, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { CustomButton } from "../components/Buttons";
import { useFormik } from "formik";
import { useState } from "react";
import { API_BASE_URL } from "../components/APIs";
import axios from "axios";

export default function SubmitApplication() {
  const [isLoading, setLoading] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  
  const uid = sessionStorage.getItem("uid");
  const toast = useToast();

  const fieldLabels = {
    nok: "Next of Kin",
    mobility_status: "Mobility Status",
    personal_info: "Personal Information",
    poi: "Proof of Identity",
    poa: "Proof of Address",
    rtw: "Right to Work",
    work_history: "Work History",
    resume: "Resume",
    certificates: "Certificates"
  };

  const formik = useFormik({
    initialValues: {
      user_id: uid,
    },
    onSubmit: (data) => {
      setLoading(true);
      const url = API_BASE_URL + "/application/submitApplication";
      axios
        .post(url, data)
        .then((response) => {
          setLoading(false);
          if (response.data.status === 200) {
            toast({
              title: "Application Feedback",
              description: response.data.message,
              position: "top",
              status: "success",
              isClosable: true,
            });
            setMissingFields([]);
          } else {
            toast({
              title: "Application Incomplete",
              description: response.data.message,
              position: "top",
              status: "error",
              isClosable: true,
            });
            if (response.data.missing_fields) {
              const missing = Object.entries(response.data.missing_fields)
                .filter(([_, value]) => value === 0)
                .map(([key]) => fieldLabels[key] || key.replace("_", " ").toUpperCase());
              setMissingFields(missing);
            }
          }
        })
        .catch(() => {
          setLoading(false);
          toast({
            title: "Error",
            description: "Something went wrong, please try again.",
            position: "top",
            status: "error",
            isClosable: true,
          });
        });
    },
  });

  return (
    <>
      <Box my={3}>
        {missingFields.length > 0 && (
          <Box mb={3} p={3} borderWidth={1} borderColor="red.400" borderRadius="md" backgroundColor="red.50">
            <Text fontWeight="bold" color="red.600">Incomplete Application Fields:</Text>
            <UnorderedList>
              {missingFields.map((field, index) => (
                <ListItem key={index} color="red.500">{field}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
        {isLoading ? (
          <Spinner
            thickness="3px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        ) : (
          <CustomButton
            type="button"
            onClick={formik.handleSubmit}
            bg={"blue.400"}
            colorScheme="blue"
            label="Submit Application"
          />
        )}
      </Box>
    </>
  );
}
